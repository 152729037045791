var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CerumConfirm } from '../CerumConfirm';
import { OverlayWindow } from 'common-uicomponents/components/OverlayWindow';
import { SingletonInstanceOf } from 'badphraim/core/Singleton';
import { AllowClosingOfModalResult } from 'common-uicomponents-defs/IOverlayWindowObject';
var PopAction;
(function (PopAction) {
    PopAction["CANCEL"] = "CANCEL";
    PopAction["CLOSE"] = "CLOSE";
    PopAction["CLOSEWITHSAVEDDATA"] = "CLOSEWITHSAVEDDATA";
})(PopAction || (PopAction = {}));
// eslint-disable-next-line @typescript-eslint/naming-convention
export function CerumConfirmModalCallback() {
    return [AllowClosingOfModalResult.ConfirmMessage, undefined, modalCallback];
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export function CerumConfirmModalCallbackWizard() {
    return [AllowClosingOfModalResult.ConfirmMessage, undefined, modalCallbackWizard];
}
export function modalCallback() {
    return __awaiter(this, void 0, void 0, function* () {
        const cerumFormHelper = CerumFormHelper.instance();
        const formBuilder = cerumFormHelper.formBuilder;
        const isDirty = Boolean(formBuilder === null || formBuilder === void 0 ? void 0 : formBuilder.isDirty);
        const modalHandle = yield cerumFormHelper.showModal(isDirty);
        const popAction = yield cerumFormHelper.getPopAction(cerumFormHelper);
        switch (popAction) {
            case PopAction.CLOSEWITHSAVEDDATA:
                OverlayWindow.asyncPopAllWindows();
                formBuilder === null || formBuilder === void 0 ? void 0 : formBuilder.reset();
                break;
            case PopAction.CANCEL:
                modalHandle === null || modalHandle === void 0 ? void 0 : modalHandle.closeOverlay();
                cerumFormHelper.confirmModalVisible = false;
                break;
            case PopAction.CLOSE:
            default:
                OverlayWindow.asyncPopWindow(undefined, true);
                break;
        }
        cerumFormHelper.confirmModalVisible = false;
    });
}
export function modalCallbackWizard() {
    return __awaiter(this, void 0, void 0, function* () {
        const cerumFormHelper = CerumFormHelper.instance();
        const formBuilder = cerumFormHelper.formBuilder;
        const isDirty = Boolean(formBuilder === null || formBuilder === void 0 ? void 0 : formBuilder.isDirty);
        const modalHandle = yield cerumFormHelper.showModal(isDirty);
        const popAction = yield cerumFormHelper.getPopAction(cerumFormHelper);
        switch (popAction) {
            case PopAction.CLOSEWITHSAVEDDATA:
                OverlayWindow.asyncPopAllWindows();
                formBuilder === null || formBuilder === void 0 ? void 0 : formBuilder.reset();
                break;
            case PopAction.CANCEL:
                modalHandle === null || modalHandle === void 0 ? void 0 : modalHandle.closeOverlay();
                cerumFormHelper.confirmModalVisible = false;
                break;
            case PopAction.CLOSE:
            default:
                OverlayWindow.asyncPopWindow(undefined, true);
                break;
        }
        cerumFormHelper.confirmModalVisible = false;
    });
}
export class CerumFormHelper {
    constructor() {
        this.confirmModalVisible = false;
        this.cerumConfirm = CerumConfirm.instance();
    }
    static instance() {
        const instance = SingletonInstanceOf(CerumFormHelper);
        return instance;
    }
    showModal(isDirty) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._showConfirmModal(isDirty)) {
                this.confirmModalVisible = true;
                if (!this.cerumConfirm.text || this.cerumConfirm.type !== 'danger') {
                    this.cerumConfirm.text = 'Du har endret data, er du sikker på at du vil lukke uten å lagre?';
                    this.cerumConfirm.type = 'danger';
                }
                return OverlayWindow.asyncPushWindow({
                    title: 'Er du sikker?',
                    element: this.cerumConfirm,
                    size: 'small',
                });
            }
        });
    }
    getModalConfirmationResult() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.cerumConfirm.getConfirmation();
        });
    }
    _showConfirmModal(isDirty) {
        return isDirty && !this.confirmModalVisible;
    }
    getPopAction(cerumFormHelper) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            let closeWithSavedData = undefined;
            if (!((_a = cerumFormHelper.formBuilder) === null || _a === void 0 ? void 0 : _a.isDirty)) {
                return PopAction.CLOSE;
            }
            closeWithSavedData = yield cerumFormHelper.getModalConfirmationResult();
            if (cerumFormHelper.confirmModalVisible && closeWithSavedData === false) {
                return PopAction.CANCEL;
            }
            else if (closeWithSavedData) {
                return PopAction.CLOSEWITHSAVEDDATA;
            }
            console.log('cerumFormHelper', cerumFormHelper);
            return PopAction.CLOSE;
        });
    }
}
