import { TaskStatus } from 'cerum-work-schedule-defs/TaskStatus';
import moment from 'moment';
import { EngagementStatus } from 'cerum-work-schedule-defs/AssigneeWithStatus';
export var WeekDay;
(function (WeekDay) {
    WeekDay["FRIDAY"] = "friday";
    WeekDay["MONDAY"] = "monday";
    WeekDay["SATURDAY"] = "saturday";
    WeekDay["SUNDAY"] = "sunday";
    WeekDay["THURSDAY"] = "thursday";
    WeekDay["TUESDAY"] = "tuesday";
    WeekDay["WEDNESDAY"] = "wednesday";
})(WeekDay || (WeekDay = {}));
export const isEngagementStatusType = (status) => {
    return status in EngagementStatus;
};
export const isTaskStatusType = (status) => {
    return status in TaskStatus;
};
export class CerumWorkScheduleTaskStatusHelper {
    taskStatusName(status) {
        if (!status) {
            return '';
        }
        if (isEngagementStatusType(status)) {
            return this.taskEngagementName(status);
        }
        if (!isTaskStatusType(status)) {
            return '';
        }
        switch (status) {
            case TaskStatus.INPROGRESS:
            case TaskStatus.SCHEDULED:
                return 'Planlagt';
            case TaskStatus.CANCELLED:
                return 'Kansellert';
            case TaskStatus.COMPLETED:
                return 'Fullført';
            case TaskStatus.OVERDUE:
                return 'Forfalt';
            case TaskStatus.INCOMPLETE:
                return 'Ufullstendig';
            case TaskStatus.SIMULATED:
                return 'Simulert';
            case TaskStatus.STARTED:
                return 'Påbegynt';
            case TaskStatus.DEVIANCE:
                return 'Avvik';
            case TaskStatus.HOLIDAY:
                return 'Ferie';
            case TaskStatus.DELETED:
                return 'Slettet';
            case TaskStatus.PROPOSED:
                return 'Foreslått';
            default:
                console.error('Ukjent Status - ' + status);
                return 'Ukjent status';
        }
    }
    taskStatusClass(task) {
        var _a;
        switch (task.completion_status) {
            case TaskStatus.COMPLETED:
                return 'task-completed';
            case TaskStatus.CANCELLED:
                return 'task-cancelled';
            case TaskStatus.INPROGRESS:
                return 'task-inprogress';
            case TaskStatus.SCHEDULED:
                return 'task-scheduled';
            case TaskStatus.OVERDUE:
                return 'task-overdue';
            case TaskStatus.INCOMPLETE:
                return 'task-incomplete';
            case TaskStatus.STARTED:
                return 'task-started';
            case TaskStatus.SIMULATED:
                if ('delivery' in task) {
                    if ((_a = task.delivery) === null || _a === void 0 ? void 0 : _a.single) {
                        return 'task-simulated-single';
                    }
                }
                else if ('single' in task && task.single) {
                    return 'task-simulated-single';
                }
                return 'task-simulated';
        }
        return;
    }
    metricStatusName(status) {
        if (isEngagementStatusType(status)) {
            return this.taskEngagementName(status);
        }
        else if (isTaskStatusType(status)) {
            return this.taskStatusName(status);
        }
        return 'Ukjent status';
    }
    taskEngagementName(engagement) {
        switch (engagement) {
            case EngagementStatus.ACTIVE:
                return 'Operatør aktiv';
            case EngagementStatus.PASSIVE:
                return 'Operatør passiv';
            case EngagementStatus.TERMINATED:
                return 'Operatør avsluttet arb.forhold';
            case EngagementStatus.UNASSIGNED:
                return 'Ikke tildelt';
            default:
                return 'Ukjent';
        }
    }
}
export class CerumWorkScheduleTaskHelper {
    constructor() {
        this.statusHelper = new CerumWorkScheduleTaskStatusHelper();
    }
    taskDayValue(day) {
        switch (day) {
            case 1:
            case 'Mandag':
                return WeekDay.MONDAY;
            case 2:
            case 'Tirsdag':
                return WeekDay.TUESDAY;
            case 3:
            case 'Onsdag':
                return WeekDay.WEDNESDAY;
            case 4:
            case 'Torsdag':
                return WeekDay.THURSDAY;
            case 5:
            case 'Fredag':
                return WeekDay.FRIDAY;
            case 6:
            case 'Lørdag':
                return WeekDay.SATURDAY;
            case 7:
            case 'Søndag':
                return WeekDay.SUNDAY;
        }
    }
    taskDayTitle(day) {
        switch (day) {
            case WeekDay.MONDAY:
            case 1:
                return 'Mandag';
            case WeekDay.TUESDAY:
            case 2:
                return 'Tirsdag';
            case WeekDay.WEDNESDAY:
            case 3:
                return 'Onsdag';
            case WeekDay.THURSDAY:
            case 4:
                return 'Torsdag';
            case WeekDay.FRIDAY:
            case 5:
                return 'Fredag';
            case WeekDay.SATURDAY:
            case 6:
                return 'Lørdag';
            case WeekDay.SUNDAY:
            case 7:
            case 0:
                return 'Søndag';
        }
    }
    roundHours(hours) {
        if (hours === undefined) {
            return '';
        }
        return Math.round(hours * 100) / 100;
    }
    relativeDuration(minutesInput) {
        if (!minutesInput) {
            return '';
        }
        const hours = Math.round((minutesInput * 10) / 60) / 10;
        if (hours < 1) {
            return minutesInput + ' minutter';
        }
        else if (hours < 2) {
            return hours + ' time';
        }
        return hours + ' timer';
    }
    isRedday(reddays, date) {
        return reddays.find((redday) => {
            const reddayMoment = moment.unix(redday.timestamp);
            return reddayMoment.isSame(date, 'days');
        });
    }
}
