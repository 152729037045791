var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reduxBind } from 'badphraim/ui/LitReduxMixinBase';
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { BoostPortalStore } from '../redux/BoostPortalStore';
import { LitReduxBoostPortalMixin } from '../redux/LitReduxBoostPortalMixin';
import { BoostStatusContainer } from './common/BoostStatusContainer';
import { BoostMarkdown } from './common/BoostMarkdown';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { isErrorHTTP, isSuccessHTTP } from 'boost-api/APIRestClient';
import { styleMap } from 'lit/directives/style-map.js';
import { CerumBadge } from 'common-uicomponents/components/CerumBadge';
import { MembersignupAPI } from 'boost-api/boost/signup/membersignup/MembersignupAPI';
import { BoostMetoderTilgjengeligEtterInnloggAPI } from 'boost-api/boost/app/boost_metoder_tilgjengelig_etter_innlogg/BoostMetoderTilgjengeligEtterInnloggAPI';
let BoostPortalChooseMembership = class BoostPortalChooseMembership extends LitReduxBoostPortalMixin(LitElement) {
    constructor() {
        super(...arguments);
        this.memberships = [];
        this.isSponsor = false;
        this.membershipProperties = [];
        this.selectedProperties = [];
        this.dropdownIsVisible = false;
        this.isHiddenMembership = false;
        this.hasTestMembership = false;
    }
    static get is() {
        return 'boost-portal-choose-membership';
    }
    static get styles() {
        return css `
            .membership {
                margin-bottom: 10px;
                padding: 25px;
                border-radius: 8px;
                color: var(--portalCardForeground, #fafafa);
                background-color: var(--portalCardBackground, #263651);
                transition: opacity 0.2s linear;
                position: relative;
            }

            .membership:hover {
                background-color: var(--portalCardBackgroundHover, #112d45);
                color: var(--portalCardForegroundHover, #fafafa);
                cursor: pointer;
            }

            .membership.active {
                background-color: var(--portalCardBackgroundActive, #112d45);
                color: var(--portalCardForegroundActive, #fafafa);
            }

            .membership .membership-title {
                flex: 1;
                font-size: 125%;
                font-weight: 100;
            }

            .membership .membership-cost {
                font-size: 150%;
                font-weight: 100;
            }

            .membership .membership-description {
                font-size: 100%;
                font-weight: 300;
            }

            vaadin-select {
                width: 100%;
            }

            h4 {
                color: var(--portalForeground, #404040);
                font-weight: 500;
                margin-bottom: 10px;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }

            .membership.hidden {
                display: none;
            }

            .membership.visuallyhidden {
                opacity: 0;
            }

            .check {
                display: none;
            }

            .active .check {
                display: block;
                position: absolute;
                top: -4px;
                color: #f6f6f6;
                right: -10px;
                background-color: var(--portalButtonSuccessBackground, #32be69) !important;
                border-radius: 50%;
                width: 36px;
                height: 36px;
            }

            .active .check svg {
                fill: var(--portalButtonSuccessForeground, #f6f6f6);
            }

            cerum-badge {
                cursor: pointer;
                font-size: 12px;
            }

            .selected-property {
                --cerum-badge-background-color: var(--portalCardBackground, #263651);
                --cerum-badge-color: var(--portalCardForeground, #fafafa);
            }

            .filter-button {
                width: fit-content;
                display: flex;
                align-items: center;
                background-color: rgb(214, 230, 245);
                border-radius: 14px;
                padding: 4px 10px;
                font-size: 16px;
                margin-bottom: 8px;
                height: 30px;
                align-self: end;
                cursor: pointer;
            }

            .property-container {
                display: flex;
                max-width: 100%;
                flex-flow: wrap;
                margin-bottom: 10px;
                gap: 2px;
            }

            .property-text-button-container {
                display: flex;
                justify-content: space-between;
            }

            .dropdown-visible {
                color: rgb(66, 61, 61);
                min-width: 12px;
                width: 12px;
                height: 13px;
                margin-left: 14px;
            }

            .dropdown-visible-rotate {
                transform: rotate(90deg);
                margin-right: 3px;
            }

            @media only screen and (max-width: 1100px) {
                .membership {
                    padding: 10px;
                    margin-top: 8px;
                }

                .membership .membership-title {
                    font-size: 18px;
                    font-weight: 100;
                    margin-bottom: 0;
                    margin-right: 0;
                }

                .membership .membership-cost {
                    font-size: 18px;
                    font-weight: 100;
                    margin-bottom: 0px;
                }

                .active .check {
                    top: -10px;
                    width: 26px;
                    height: 26px;
                }

                svg {
                    width: 26px;
                    height: 26px;
                }

                .property-text-button-container {
                    display: block;
                }
            }
        `;
    }
    /** @override */ render() {
        return this._renderMembershipContainer();
    }
    _renderMembershipContainer() {
        const memberships = this._filterMemberships();
        return html `
            ${this._isMembershipPropertiesLength() ? this._renderFilterText() : this._renderFilterButton()}
            ${this.dropdownIsVisible
            ? html `<div class="property-container">${this._renderAvailableMembershipProperties()}</div>`
            : html ``}
            ${memberships.length
            ? this._renderMemberships(memberships)
            : this._renderStatusContainer(memberships)}
        `;
    }
    _renderFilterText() {
        if (this.membershipProperties.length) {
            return html `<h4>Klikk på ett eller flere filtre for å se medlemskap som passer dine ønsker</h4>
                <div class="property-container">${this._renderAvailableMembershipProperties()}</div>`;
        }
        return html `<h4>Velg ditt medlemskap</h4>`;
    }
    _renderFilterButton() {
        return html `<div class="property-text-button-container">
            <h4>Klikk på filterknappen for å se tilgjengelige filtre</h4>
            <div class="filter-button" @click="${() => this._onToggleFilterButton()}">
                <span> Velg filter </span>
                <svg
                    class="dropdown-visible  ${this.dropdownIsVisible ? 'dropdown-visible-rotate' : ''}"
                    viewBox="0 0 24 24"
                >
                    <path
                        d="M5.5.75l10.72 10.72a.749.749 0 010 1.06L5.5 23.25"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                    ></path>
                </svg>
            </div>
        </div>`;
    }
    _isMembershipPropertiesLength() {
        return this.membershipProperties.length <= 4;
    }
    _onToggleFilterButton() {
        this.dropdownIsVisible = !this.dropdownIsVisible;
    }
    _renderStatusContainer(memberships) {
        if (this.selectedProperties.length && !memberships.length) {
            return html ` <boost-status-container type="warning">
                Ingen tilgjengelige medlemskap med valgte filter
            </boost-status-container>`;
        }
        if (!this.selectedDepartmentId) {
            return html `
                <boost-status-container type="info">
                    Du må velge senter før du kan velge medlemskap
                </boost-status-container>
            `;
        }
        if (!this.selectedMembershipId && !memberships.length) {
            return html `
                <boost-status-container type="warning">
                    Ingen tilgjengelige medlemskap
                </boost-status-container>
            `;
        }
    }
    _loadMemberships() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.selectedDepartmentId) {
                return;
            }
            const groupIds = this.selectedProperties.map((property) => property);
            const membershipsResult = yield MembersignupAPI.getV1BoostsignupDepartmentsMembershipsUsingDepartmentid(String(this.selectedDepartmentId), { include_hidden: true, group_ids: groupIds });
            if (isSuccessHTTP(membershipsResult)) {
                this.dispatch(BoostPortalStore.Actions.SetMemberships(membershipsResult.sort(this._membershipsComparator)));
            }
            else {
                SnackBar.pushError(membershipsResult.error);
            }
        });
    }
    _membershipsComparator(lhs, rhs) {
        return lhs.is_featured === rhs.is_featured ? 0 : lhs.is_featured ? -1 : 1;
    }
    _getMembershipClass(membership) {
        if (!this.selectedMembershipId) {
            return 'membership';
        }
        const classes = ['membership'];
        if (membership.id === this.selectedMembershipId) {
            classes.push('active');
        }
        else {
            classes.push('visuallyhidden', 'hidden');
        }
        return classes.join(' ');
    }
    _selectedMembership(membershipId) {
        if (this.isHiddenMembership) {
            return;
        }
        if (membershipId === this.selectedMembershipId) {
            this.dispatch(BoostPortalStore.Actions.SetSelectedMembershipId(undefined));
        }
        else {
            this.dispatch(BoostPortalStore.Actions.SetSelectedMembershipId(membershipId));
        }
    }
    _membershipsObserver() {
        if (!this.memberships.length) {
            return;
        }
        if (this.memberships.length === 1 && !this.selectedProperties.length) {
            this._selectedMembership(this.memberships[0].id);
        }
        this._checkIfMembershipIsValid(this.selectedMembershipId);
    }
    _selectedMembershipIdObserver() {
        if (!this.selectedMembershipId) {
            return;
        }
        this._checkIfMembershipIsValid(this.selectedMembershipId);
    }
    _checkIfMembershipIsValid(membershipId) {
        if (!membershipId || !this.memberships.length) {
            return;
        }
        if (!this.memberships.flatMap((membership) => membership.id).includes(membershipId)) {
            this.dispatch(BoostPortalStore.Actions.SetSelectedMembershipId());
            SnackBar.push({
                title: 'En feil har oppstått',
                subtitle: 'Medlemskapet du ønsker er ikke lengre tilgjengelig. Velg et annet medlemskap',
                type: 'danger',
            });
        }
    }
    _getMemberhipPropertyClasses(propertyId) {
        const classes = [];
        if (this._isSelectedProperty(propertyId)) {
            classes.push('selected-property');
        }
        return classes.join(' ');
    }
    _renderAvailableMembershipProperties() {
        if (this.isHiddenMembership) {
            return;
        }
        return this.membershipProperties.map((property) => html `<div style="margin:3px 0px">
                    <cerum-badge
                        @click="${() => this._propertyChanged(property.id)}"
                        class="${this._getMemberhipPropertyClasses(property.id)}"
                        type="info"
                        >${property.name}</cerum-badge
                    >
                </div> `);
    }
    _filterActiveCategory() {
        return __awaiter(this, void 0, void 0, function* () {
            const filterDocument = yield MembersignupAPI.getV1BoostsignupDepartmentsMembershipsGroups();
            if (isSuccessHTTP(filterDocument)) {
                this.membershipProperties = filterDocument.filter((property) => property.is_active);
            }
        });
    }
    _isSelectedProperty(property) {
        return this.selectedProperties.find((selectedProperty) => property === selectedProperty);
    }
    _propertyChanged(propertyId) {
        if (this._isSelectedProperty(propertyId)) {
            this.selectedProperties = this.selectedProperties.filter((selectedProperty) => selectedProperty !== propertyId);
            this.dispatch(BoostPortalStore.Actions.SetSelectedMembershipId(undefined));
            return;
        }
        this.selectedProperties.push(propertyId);
        this.requestUpdate('selectedProperties');
    }
    _isValidSponsorMemberships(membership) {
        if (membership.id === this.selectedMembershipId) {
            return true;
        }
        if (membership.temporary === false && membership.is_direct_link_only === false) {
            return true;
        }
        if (membership.temporary !== false && membership.is_direct_link_only !== false) {
            return true;
        }
        return false;
    }
    _getMembershipStyles() {
        if (this.isHiddenMembership) {
            return { cursor: 'auto' };
        }
        return { cursor: 'pointer' };
    }
    _filterMemberships() {
        if (this.isSponsor) {
            return this.memberships.filter((membership) => this._isValidSponsorMemberships(membership));
        }
        if (this.loggedInProfile) {
            return this.memberships.filter((membership) => !membership.test_membership);
        }
        return this.memberships.filter((membership) => membership.is_direct_link_only === false || membership.id === this.selectedMembershipId);
    }
    _renderMemberships(memberships) {
        if (this.loggedInProfile && this.hasTestMembership) {
            return html `
                <boost-status-container type="warning">
                    Medlemskapet er ikke tilgjengelig
                </boost-status-container>
            `;
        }
        return html ` ${memberships.map((membership) => this._renderMembership(membership))} `;
    }
    _renderMembership(membership) {
        var _a;
        return html `
            <div
                style="${styleMap(this._getMembershipStyles())}"
                class="${this._getMembershipClass(membership)}"
                @click="${() => this._selectedMembership(membership.id)}"
            >
                <div class="check">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs"
                        viewBox="0 0 140 140"
                        width="36"
                        height="36"
                    >
                        <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
                            <path
                                d="M18.48,6.449a1.249,1.249,0,0,0-1.747.265l-5.924,8.04L7.042,11.74a1.251,1.251,0,0,0-1.563,1.953l4.783,3.826a1.263,1.263,0,0,0,1.787-.235l6.7-9.087A1.25,1.25,0,0,0,18.48,6.449Z"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="0"
                            ></path>
                        </g>
                    </svg>
                </div>
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <div class="membership-title">${membership.title}</div>
                    ${membership.cost_included_vat
            ? html `<div class="membership-cost">${membership.cost_included_vat},-</div>`
            : html ``}
                </div>
                <div class="membership-description">
                    <boost-markdown
                        elementId="${membership.id}"
                        markdown="${(_a = membership.description) !== null && _a !== void 0 ? _a : ''}"
                    ></boost-markdown>
                </div>

                <div class="property-container">
                    ${this._renderMembershipProperties(membership.group_ids)}
                </div>
            </div>
        `;
    }
    _renderMembershipProperties(groupIds) {
        return groupIds.map((groupId) => {
            const membershipProperty = this.membershipProperties.find((property) => property.id === groupId);
            return html ` <cerum-badge type="info">${membershipProperty === null || membershipProperty === void 0 ? void 0 : membershipProperty.name}</cerum-badge>`;
        });
    }
    _isHiddenMembershipObserver() {
        var _a;
        if (!this.memberships.length || !this.selectedMembershipId) {
            return;
        }
        const selectedMembership = this.memberships.find((membership) => membership.id === this.selectedMembershipId);
        this.isHiddenMembership = (_a = selectedMembership === null || selectedMembership === void 0 ? void 0 : selectedMembership.is_direct_link_only) !== null && _a !== void 0 ? _a : false;
    }
    _dispatchHiddenMembership() {
        this.dispatchEvent(new CustomEvent('hiddenMembership-changed', {
            detail: { value: this.isHiddenMembership },
            bubbles: true,
            composed: true,
        }));
    }
    _sessionObserver() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.session) {
                return;
            }
            const profileResult = yield BoostMetoderTilgjengeligEtterInnloggAPI.getExerciseProfile({
                session: this.session,
            });
            if (isErrorHTTP(profileResult)) {
                SnackBar.pushError(profileResult.error);
                return;
            }
            this.dispatch(BoostPortalStore.Actions.SetLoggedInProfile(profileResult));
        });
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'token':
                    this._filterActiveCategory();
                    break;
                case 'session':
                    this._sessionObserver();
                    break;
                case 'memberships':
                    this._membershipsObserver();
                    this._isHiddenMembershipObserver();
                    break;
                case 'selectedMembershipId':
                    this._isHiddenMembershipObserver();
                    this._selectedMembershipIdObserver();
                    break;
                case 'isHiddenMembership':
                    this._dispatchHiddenMembership();
                    break;
                case 'selectedDepartmentId':
                case 'selectedProperties':
                    this._loadMemberships();
                    break;
            }
            super.updated(changedProperties);
        });
    }
};
BoostPortalChooseMembership._deps = [BoostStatusContainer, BoostMarkdown, CerumBadge];
__decorate([
    reduxBind(BoostPortalStore.path('memberships')),
    state(),
    __metadata("design:type", Array)
], BoostPortalChooseMembership.prototype, "memberships", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedDepartmentId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalChooseMembership.prototype, "selectedDepartmentId", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('selectedMembershipId')),
    state(),
    __metadata("design:type", Number)
], BoostPortalChooseMembership.prototype, "selectedMembershipId", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('token')),
    state(),
    __metadata("design:type", String)
], BoostPortalChooseMembership.prototype, "token", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('session')),
    state(),
    __metadata("design:type", String)
], BoostPortalChooseMembership.prototype, "session", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostPortalChooseMembership.prototype, "isSponsor", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], BoostPortalChooseMembership.prototype, "membershipProperties", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], BoostPortalChooseMembership.prototype, "selectedProperties", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalChooseMembership.prototype, "dropdownIsVisible", void 0);
__decorate([
    reduxBind(BoostPortalStore.path('loggedInProfile')),
    state(),
    __metadata("design:type", Object)
], BoostPortalChooseMembership.prototype, "loggedInProfile", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostPortalChooseMembership.prototype, "hasTestMembership", void 0);
BoostPortalChooseMembership = __decorate([
    customElement(BoostPortalChooseMembership.is)
], BoostPortalChooseMembership);
export { BoostPortalChooseMembership };
